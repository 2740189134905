<template>
  <div>
    <div class="logo">
      <a href="">
        <img src="../../assets/images/register_logo.png" alt="">
        <p>一站式工业仪器仪表交易平台-买卖仪器仪表就上仪商城</p>
      </a>
    </div>
    <div class="main">
      <div class="con">
        <div class="main_register">
          <div class="mine">
            <h2 style="margin-bottom:15px">登录账户</h2>
            <p style="margin-bottom:15px" v-show="error"><i class="el-icon-s-opportunity"></i>登录名或登录密码不正确</p>
            <el-input style="margin-bottom:15px" type="text" v-model="username" placeholder="用户名" />
            <el-input style="margin-bottom:15px" type="password" v-model="password" placeholder="密码" />
            <el-button style="margin-bottom:15px" type="primary" @click="login()" @mouseover="add()" @mouseout="del()" ref="btn">登录</el-button>

            <el-row style="margin-bottom:15px;text-align:left">
              <el-col :span="12" style="   text-align: left;">
                <router-link to="/register">立即注册</router-link>
              </el-col>
              <el-col :span="12" style="   text-align: right;">
                <router-link to="/forgetPassword">忘记密码</router-link>
              </el-col>
            </el-row>
          </div>
          <!-- <div class="button">
            <h4>————— 其他登录方式 —————</h4>
            <a>
              <li><img
                  src="../../assets/images/zhi.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/images/wx.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/images/weibo.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/images/weibo.png"
                  alt=""
                ></li>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import identity from "@/assets/utils/identity";
import { getToken } from '@/assets/config/token';

export default {
  data() {
    return {
      username: "",
      password: "",
      error: false,
      from: this.$route.query.url || '/'
    };
  },
  created() {
    if (getToken()) this.$router.replace(this.from).catch(() => { });

    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      // var thisdkeyvalue = document.temt ? $ever.keyCode.d : e.wathck
      if (keycode == 13) {
        that.login(); // 登录方法名
        return false;
      }
    };
  },
  methods: {
    add() {
      this.$refs.btn.style.background = "#ddd";
      this.$refs.btn.style.color = "#000";
    },
    del() {
      this.$refs.btn.style.background = "#000";
      this.$refs.btn.style.color = "#fff";
      // this.$refs.btn.style.overflow = 'hidde'
    },
    login() {
      if (!this.username || !this.password) {
        this.$message('账号或密码不能为空');
        return false;
      }

      this.$post("home/user/login", {
        username: this.username,
        password: this.password
      }).then(res => {
        identity.login(res, this.from);
      });

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";
// color: #D64848;
::v-deep .is-active {
  color: #e74c26;
}
.logo {
  background: #fff;

  width: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  height: 36px;
  padding: 20px 0 70px;
  img {
    width: 150px;
    height: 50px;
    float: left;
  }
  p {
    float: left;
    line-height: 72px;
    margin-left: 10px;
    color: #000;
    font-size: 25px;
    font-family: "KaiTi", Georgia, Serif;
  }
}
.main {
  background: url(../../assets/images/register_main.jpg) #e94c15 no-repeat
    center center;
  position: relative;
  width: 100%;
  height: 750px;
  overflow: hidden;
  .con {
    width: 1226px;
    box-sizing: border-box;
    margin: 0 auto;
    .main_register {
      width: 350px;
      height: 270px;
      background: #fff;
      float: right;

      box-sizing: border-box;
      position: relative;
      right: 100px;
      top: 100px;
      padding: 20px;
      .mine {
        h2 {
          font-size: 16px;
        }
        p {
          width: 100%;
          height: 30px;
          line-height: 30px;
          background-color: #fef2f2;
          border-color: #ffb4a8;
          border: 1px solid #ff934c;
          i {
            color: #ce201e;
            margin-left: 5px;
          }
        }
        input {
          width: 100%;
          height: 45px;
          margin: 0 auto;
          text-indent: 10px;
        }
        button {
          display: block;
          width: 100%;
          height: 45px;

          color: #fff;
          cursor: pointer;
          font-size: 17px;
          // position:absolute;
          // top:50%;
          // left:50%;
          // transform:translate(-50% -50%);
          // font-family:"KaiTi",Georgia,Serif;
          // overflow:hidden;
        }
        a {
          color: red;
          font-size: 12px;
          margin-right: 25px;
        }
      }
      .button {
        position: absolute;
        bottom: 30px;
        left: 62.5px;
        font-size: 16px;
        h4 {
          text-align: center;
        }
        a {
          float: left;
          margin-top: 30px;
        }
        :nth-child(n + 2) {
          margin-right: 0;
          margin-left: 25px;
        }
        .diudiu {
          position: absolute;
          top: 5%;
          left: 5%;
        }
      }
    }
  }
}
</style>
